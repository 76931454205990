
import React, {useEffect,useState } from 'react';

import { useNavigate, Link } from 'react-router-dom'   
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next'; 

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function RechargeType() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }     
    }, []); 
 
     
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    
    const [wallettype, setWallettype] = useState(['trc20', 'erc20']);   
    
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper recharge-wrapper">  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Deposit Channel')}</span>
                    <span></span>
                </div>
                <div className="account-lists">  
                    <div  className="order-list-items" ><Link to={`/deposit/erc20`}>erc20</Link > </div>
                    <div  className="order-list-items" ><Link to={`/deposit/trc20`}>trc20</Link > </div>
                </div>
            </div> 
        </>
    );
};