
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'   
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField';
 
import { GetDeposit, PostDeposit, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Recharge() {  
 
    const { t } = useTranslation();
    const { type } = useParams();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetRechargeInfo();   
    }, []); 

    const GetRechargeInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            //let pay_name = type; 
            let pay_name = "trc20"; 
            GetDeposit({
                token,
                pay_name
            }, CallbackRechargeInfo);  
        }
    } 
    
    const CallbackRechargeInfo = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data;
            setWallettype(data.wallet_type);   
            setWalletaddress(data.wallet_address);  
        }  
    } 
     
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    
    const [wallettype, setWallettype] = useState('');   
    const [walletaddress, setWalletaddress] = useState('');   
    const [amount, setAmount] = useState('');   
    const [hashcode, setHashcode] = useState('');    
  
    const handleDeposit = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        // let pay_name = type; 
        let pay_name = "trc20"; 
        PostDeposit({ 
            token,
            amount,
            hashcode,
            pay_name
        }, CallbackDeposit);
    } 
 
    const CallbackDeposit = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data;
            notyf.success(t(returnData.message));  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    } 
      
    useEffect(() => {
        setIsDisabled(amount === '' || hashcode === ''); 
    }, [amount, hashcode]);

  
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Deposit')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper mt-5">  
                    <form className="form" onSubmit={handleDeposit} >   
                        <div className="form-group">  
                            <TextField size="small" value={wallettype} label={t('Wallet Type')} variant="outlined"   readOnly disabled/>
                        </div>  
                        <div className="form-group">  
                            <TextField size="small" value={walletaddress} label={t('Wallet Address')} variant="outlined"   readOnly disabled/>
                        </div>  
                        <div className="form-group">  
                            <TextField type="number" required size="small" label={t('Deposit Amount')} variant="outlined"  onChange={(e)=>inputChangeHandler(setAmount, e)}/>
                        </div>
                        <div className="form-group">  
                            <TextField multiline  rows={4} required size="small" label={t('Hashcode')} variant="outlined"  onChange={(e)=>inputChangeHandler(setHashcode, e)}/> 
                        </div>  
                         
                        <div className="form-action">   
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Submit')}</Button> 
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};